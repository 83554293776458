




import { Component, Vue } from "vue-property-decorator";
//import axios from "@/axios";
@Component
export default class HotelInfo extends Vue {
  data() {
    return {
      errors: []
    };
  }

  // Gets Hotel Data
  // getHotelData = () => {
  //   const HotelUrlKey = this.$route.params.HotelUrlKey;
  //   if (!HotelUrlKey) return;
  //   const Url = `${process.env.VUE_APP_APIURL}/hotelinfo/${HotelUrlKey}`;
  //   const data = {};
  //   axios
  //     .post(Url, data)
  //     .then(response => {
  //       this.$store.dispatch("setHotelBookingInfo", response.data).then(() => {
  //         this.$store.commit("setUILoading", false);
  //         if (response.data.hotel.HotelID) this.$router.push("/");
  //       });
  //     })
  //     .catch(e => {
  //       console.log("error", e);
  //     });
  // };

  created() {
    //this.getHotelData();
  }
}
